<template>
  <OrderProductsList />
</template>

<script>
  export default {
    components: {
      OrderProductsList: () => import('@/components/comercial/order-products/OrderProductsList')
    }
  }
</script>